export const assignedEntitiesColumns = [
  {
    field: 'sgaId',
    title: 'SGA ID',
    width: '150px',
    columnMenu: false,
    filterable: false,
    groupable: false
  },
  {
    field: 'requestId',
    title: 'Request ID',
    width: '140px',
    columnMenu: false,
    filterable: false,
    groupable: false
  },
  {
    field: 'legalEntityName',
    title: 'Legal Entity Name',
    width: '400px',
    resizable: true,
    className: 'text-center',
    columnMenu: false,
    filterable: false,
    groupable: false,
    cell: 'legalEntityTemplate'
  },
  {
    field: 'countryName',
    title: 'Country Of Domicile',
    width: '230px',
    resizable: true,
    className: 'text-center',
    columnMenu: false,
    filterable: false,
    groupable: false
  },
  {
    field: 'immediateParentName',
    title: 'Immediate Parent Name',
    width: '230px',
    resizable: true,
    className: 'text-center',
    columnMenu: false,
    filterable: false,
    groupable: true
  },
  {
    field: 'ultimateParentName',
    title: 'Ultimate Parent Name',
    width: '230px',
    resizable: true,
    className: 'text-center',
    columnMenu: false,
    filterable: false,
    groupable: true
  },
  {
    field: 'TAT',
    title: 'TAT',
    text: 'TAT',
    width: '170px',
    cell: 'tatTemplate',
    filter: 'date',
    filterable: false,
    groupable: false
  },
  {
    field: 'assignedTo',
    title: 'Assigned To',
    width: '150px',
    sortable: false,
    columnMenu: false,
    filterable: false,
    groupable: false
  },
  {
    field: 'status',
    title: 'Status',
    width: '140px',
    sortable: true,
    columnMenu: 'statusTemplate',
    groupable: true
  },
  {
    field: 'workType',
    title: 'Work Type',
    width: '300px',
    cell: 'workTypeTemplate',
    sortable: false,
    columnMenu: false,
    filterable: false,
    groupable: false
  },
  {
    field: 'isOnHoldValue',
    title: 'On Hold',
    width: '140px',
    cell: 'onHoldStatusTemplate',
    sortable: true,
    columnMenu: 'statusTemplate'
  },
  {
    field: 'onHoldReasonName',
    title: 'On Hold Reason',
    text: 'On Hold Reason',
    width: '170px',
    groupable: false,
    sortable: false,
    columnMenu: 'statusTemplate'
  },
  {
    field: 'isUpdatedValue',
    title: 'Is Updated',
    width: '140px',
    sortable: true,
    columnMenu: 'statusTemplate'
  },
  {
    field: 'updateReason',
    title: 'Update Reason',
    width: '170px',
    sortable: true,
    columnMenu: 'statusTemplate'
  }
]

export const onHoldDropDownOptions = [
  { text: 'Yes', value: 'Yes' },
  { text: 'No', value: 'No' }
]

export const filterOperators = {
  text: [
    {
      text: 'grid.filterNotContainsOperator',
      operator: 'doesnotcontain'
    },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
    { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
    { text: 'grid.filterIsNullOperator', operator: 'isnull' },
    { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' },
    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
    { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' }
  ],
  numeric: [
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    { text: 'grid.filterGteOperator', operator: 'gte' },
    { text: 'grid.filterGtOperator', operator: 'gt' },
    { text: 'grid.filterLteOperator', operator: 'lte' },
    { text: 'grid.filterLtOperator', operator: 'lt' },
    { text: 'grid.filterIsNullOperator', operator: 'isnull' },
    { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' }
  ],
  date: [
    { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
    { text: 'grid.filterAfterOperator', operator: 'gt' },
    { text: 'grid.filterBeforeOperator', operator: 'lt' },
    { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
    { text: 'grid.filterIsNullOperator', operator: 'isnull' },
    { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' }
  ],
  boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }]
}
